import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

export default function BaseLayout({ children }) {
	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: 'en',
				}}
			/>
			<div className="flex flex-col min-h-screen">
				<Navbar />
				<div className="flex-1">{children}</div>
				<Footer />
			</div>
		</>
	);
}
