import * as React from 'react';
import { Link } from 'gatsby';

import './style.scss';

export default function Footer() {
	return (
		<footer className="w-full p-6 md:p-8 text-sm border-t border-white border-opacity-10">
			<div className="grid grid-cols-1 md:grid-cols-2">
				<div className="text-center md:text-left">
					<p>
						&copy; Attiqk {new Date().getFullYear()} —{' '}
						<Link to="/privacy-policy" className="link">
							Privacy Policy
						</Link>
					</p>
				</div>
				<div className="text-center md:text-right">
					<p>
						<a
							href="https://madebyverse.com"
							target="_blank"
							rel="noreferrer"
							className="link"
						>
							Made by Verse
						</a>
					</p>
				</div>
			</div>
		</footer>
	);
}
