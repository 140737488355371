import React, { useState } from 'react';
import { Link } from 'gatsby';
import './style.scss';

export default function Menu(props) {
	const [state, setState] = useState('');
	const openMenu = () => {
		setState(state === '' ? 'is-open' : '');
		document.body.classList.toggle('overflow-hidden');
	};
	const closeMenu = () => {
		setState(state === '' ? 'is-open' : '');
		document.body.classList.remove('overflow-hidden');
	};
	return (
		<>
			<button onClick={openMenu} className={`menu-trigger ${state}`}>
				<span>Menu</span>
				<span className="menu-trigger__dot"></span>
			</button>
			<nav className={`menu ${state}`} role="navigation">
				<ul>
					{NavItems.map((data, key) => {
						return (
							<li key={key}>
								<Link
									to={data.link}
									className="menu__link"
									onClick={closeMenu}
								>
									{data.title}
								</Link>
							</li>
						);
					})}
				</ul>
			</nav>
		</>
	);
}

const NavItems = [
	{
		title: 'Projects',
		link: '/',
	},
	{
		title: 'About us',
		link: '/about',
	},
	{
		title: 'Contact',
		link: '/contact',
	},
];
