import React from 'react';
import { Link } from 'gatsby';
import './style.scss';
import Menu from './Menu';
import logo from '../../images/logo.svg';

export default function Navbar() {
	return (
		<header className="navbar">
			<div>
				<Link to="/" className="navbar__logo">
					<img src={logo} alt="Attiqk logo" />
				</Link>
			</div>
			<div className="col-span-2">
				<Menu />
			</div>
		</header>
	);
}
